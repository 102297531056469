<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <div class="talk_bottom">
      <img src="../assets/img/local.jpg" alt="" style="width:100%" draggable="false">
    </div>
    <div class="talk_content">
      <div class="talk_left">
        <h1>在线留言</h1>
        <div>
          <el-form :model="queryParams" label-position="left" label-width="80px" :rules="rules" ref="queryForm" :inline="true">
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="queryParams.name" maxlength="5" placeholder="请输入姓名" clearable />
            </el-form-item>
            <el-form-item label="微信号:" prop="wechatNo">
              <el-input v-model="queryParams.wechatNo" maxlength="30" placeholder="请输入微信号" clearable />
            </el-form-item>
            <el-form-item label="电话:" prop="phone">
              <el-input v-model="queryParams.phone" maxlength="11" placeholder="请输入电话" clearable />
            </el-form-item>
            <div>
              <el-form-item label="留言内容:" prop="leaveMsg" style="width: 100%;" label-width="100px">
                <el-input style="width: 504px" maxlength="60" v-model="queryParams.leaveMsg" type="textarea" :rows="5" placeholder="请输入留言内容" clearable />
              </el-form-item>
            </div>
            <el-form-item label="验证码:" prop="code">
              <el-input style="width:200px;" v-model="queryParams.code" placeholder="请输入验证码" clearable />
            </el-form-item>
            <div class="login-code">
              <el-skeleton :loading="imgLoading" animated :rows="1" style="width: 100%">
                <template slot="template">
                  <el-skeleton-item variant="image" style="width: 100%; height: 44px" />
                </template>
              </el-skeleton>

              <el-image v-show="!imgLoading" :src="codeUrl" @click.native="getCode" class="login-code-img">
                <div slot="placeholder" class="image-slot">
                  渲染中<i class="el-icon-loading" style="margin-left: 4px"></i>
                </div>
                <div slot="error" class="image-slot">
                  <span style="margin-right: 4px">重试</span>
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div style="text-align:center">
              <el-button type="primary" style="width:200px" @click="submit">提交</el-button>
            </div>
          </el-form>

        </div>
      </div>
      <div class="talk_right">
        <h1>联系方式</h1>
        <div class="talk-txt">热线电话</div>
        <div class="talk-txt">TEL 19986889686</div>
        <div class="talk-txt">热线电话</div>
        <div class="talk-txt">TEL 18072868582</div>
        <div class="talk-txt">联系地址</div>
        <div class="talk-txt">浙江省杭州市西湖区金蓬街368号1栋5层</div>
        <div class="talk_top_cont_r">
          <img src="../assets/img/gongzhonghao.jpg" alt="" style="width: 130px;height: 130px;" />
        </div>
      </div>
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import { getCodeImg, commit } from "../api/index.js"
import footer2 from "../components/footer.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,

  },
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      Img1: require("../assets/img/banner1.png"),
      queryParams: {},
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        phone: [
          { required: true, trigger: "blur", message: "请输入您的手机号" },
          { validator: checkPhone, trigger: 'blur' }
        ],
        code: [
          { required: true, trigger: "blur", message: "请输入您的验证码" },
        ],
      },
      uuid: '',
      imgLoading: true, //图片loading
      codeUrl: "",
    };
  },
  mounted () {
    this.getCode();
  },
  methods: {
    getCode () {
      this.imgLoading = true;
      getCodeImg()
        .then((res) => {
          this.codeUrl = "data:image/png;base64," + res.img;
          this.imgLoading = false;
          this.uuid = res.uuid
        })
        .catch(() => (this.imgLoading = false));
    },
    submit () {
      this.$refs["queryForm"].validate(async (valid) => {
        if (valid) {
          let params = {
            uuid: this.uuid,
            ...this.queryParams
          }
          commit(params).then((res) => {
            if (res.code == 200) {
              this.$message.success('提交成功')
              this.$refs.queryForm.resetFields();
            }
          })
        }
      });

    },
  },
};
</script>
<style scoped>
/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
}
.talk_bottom {
  width: 100%;
  margin-bottom: 81px;
}
.talk_content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border: 1px solid #afafaf;
  width: 1200px;
  margin: 20px auto 80px;
  background: #7d94f1;
}
.talk_left {
  width: 700px;
  padding: 20px 40px;
  background: #fff;
}
.login-code {
  width: 120px;
  height: 44px;
  float: right;
  cursor: pointer;
  margin-right: 58px;
}
.login-code img {
  cursor: pointer;
}
.login-code-img {
  width: 100%;
  height: 100%;
}
::v-deep .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #909399;
  background-color: #eef0f3;
}
::v-deep .el-skeleton__image svg {
  width: 60%;
  height: 60%;
}
.talk_right {
  width: 600px;
  background: #7d94f1;
  padding: 20px 40px;
}
.talk-txt {
  font-size: 20px;
  font-weight: 600;
  margin: 6px 0;
}
</style>


